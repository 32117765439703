
const baseColors = {
  main: {
    color: '#17526C',
  },
  green: {
    color: '#B1DE79',
    dark: '#5F9022',
  },
  yellow: {
    color: '#FFE17C',
    dark: '#FF9300',
  },
  blue1: {
    color: '#87D4FF',
    dark: '#287DAD',
  },
  blue2: {
    color: '#96B3EB',
    dark: '#5475B5',
  },
}

export default {
  'home': {
    main: baseColors.main.color,
    hover: baseColors.main.color,
    transition: baseColors.main.color,
  },
  'mentions-legales': {
    main: baseColors.yellow.color,
    hover: baseColors.yellow.dark,
    transition: baseColors.yellow.color,
  },
  'conte': {
    main: baseColors.green.color,
    hover: baseColors.green.dark,
    transition: baseColors.green.color,
  },
  'pedagogique': {
    main: baseColors.blue1.color,
    hover: baseColors.blue1.dark,
    transition: baseColors.blue1.color,
  },
  'ressources': {
    main: baseColors.yellow.color,
    hover: baseColors.yellow.dark,
    transition: baseColors.yellow.color,
  },
  'audio': {
    main: baseColors.blue2.color,
    hover: baseColors.blue2.dark,
    transition: baseColors.blue2.color,
  },
};
