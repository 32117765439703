import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useForm } from 'react-hook-form';
import { useAuth } from '../constants/use-auth.js';
import GLOBAL_MEDIA_QUERIES from '../constants/media-queries';
import Modal from 'react-modal';
import { AiOutlineCloseCircle as IconClose } from 'react-icons/ai';

function UserLoginForm({ auth }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { handleSubmit, register, errors, setError } = useForm();
  const onSubmit = ({ email, password }) => {
    auth.signin(email, password).catch(() => {
      setError("password", {
        type: "manual",
        message: "Connexion refusée, vérifiez votre identifiant et votre mot de passe ou contactez un administrateur."
      });
    });
  };
  return <>
    <h1>Se connecter</h1>
    <form onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="email">
        <span>Adresse email</span>
        <input
          name="email"
          type="email"
          ref={register({
            required: "Obligatoire",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Adresse email invalide"
            }
          })}
        />
      </label>
      {errors.email && (<p className="error">{errors.email.message}</p>)}

      <label htmlFor="password">
        <span>Mot de passe</span>
        <input
          name="password"
          type="password"
          ref={register({
            required: "Obligatoire",
          })}
        />
      </label>
      {errors.password && (<p className="error">{errors.password.message}</p>)}

      <button type="submit" className="btn btn-primary">Se connecter</button>
      
    </form>
    <button className="btn btn-link" onClick={() => setModalIsOpen(true)}>Mot de passe oublié ?</button>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      contentLabel="Mot de passe oublié ?"
      className="modal-standard modal-error"
      overlayClassName="modal-overlay"
      ariaHideApp={false}
    >
      <div className="inner">
        <p>Votre mot de passe a été envoyée par un administrateur. Merci de vous rapprocher d'eux.</p>
      </div>
      <button className="close" onClick={() => setModalIsOpen(false)} title="Fermer"><IconClose /><span className="sr-only">Fermer</span></button>
    </Modal>
  </>
}
export default function UserLogin() {
  const data = useStaticQuery(graphql`
    query {
      mobileLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 225, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  const auth = useAuth();
  const sources = [
    data.mobileLogo.childImageSharp.fluid,
    {
      ...data.desktopLogo.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.tabletdesktop,
    },
  ];
  return <>
    <div className="container-mini user-wrapper">
      <div className="logo">
        <Img fluid={sources} />
      </div>
      <UserLoginForm auth={auth} />
    </div>
  </>
}
