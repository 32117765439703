import React from 'react';

import PageContextConsumer, { PageContextProviderComponent } from '../constants/page-context';
import { ProvideAuth, useAuth } from '../constants/use-auth.js';

import SEO from '../components/SEO';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Login from '../components/UserLogin';
import InstallPrompt from '../components/InstallPrompt';

import 'typeface-montserrat';

import '../scss/style.scss';
import Link from '../components/Link';

function App({ children, pageContext }) {
  const auth = useAuth();
  const userOK = auth.user;
  const userAuthorized = auth.authorizeRoute(pageContext);
  return <PageContextProviderComponent values={{
      page: pageContext.pathname || 'home',
      ...pageContext,
    }}>
      <PageContextConsumer>
        {({ data }) => <div className={`page-layout page--${data.page} page--${data.menuIsOpen ? 'with-opened-menu' : 'with-closed-menu'} user--${userOK ? 'logged-in' : 'anonymous'}`}>
          <SEO />
          {userAuthorized ? (<>
            { userOK ? <Header /> : null }
            <div className="main">{ children }</div>
            { userOK ? <Footer /> : null }
          </>) : (<>
            { userOK ? <p>Erreur !<Link to="/" className="btn btn-primary">Retour à l'accueil</Link></p> : (<>
              { userOK === null ? <p>Chargement...</p> : <Login /> }
            </>) }
          </>)}
        </div>}
      </PageContextConsumer>
    </PageContextProviderComponent>
  ;
}

export default function Layout({ children, pageContext }) {
  return <>
    <ProvideAuth>
      <App pageContext={pageContext}>{ children }</App>
    </ProvideAuth>
    <InstallPrompt />
  </>
}
