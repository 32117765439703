import React from 'react';

import AniLink from 'gatsby-plugin-transition-link/AniLink';

import colors from '../constants/colors';

export default function Link({ children, to, ...props }) {
  const toClean = to.replace('/', '-').substring(1);
  return <AniLink className={`link-to-${toClean}`} paintDrip hex={colors[toClean] !== undefined ? colors[toClean].transition : colors['home'].transition} to={to} {...props}>{ children }</AniLink>
}
