import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { FiMenu as MenuIcon } from 'react-icons/fi';
import { RiCloseLine as MenuCloseIcon } from 'react-icons/ri';

import PageContextConsumer from '../constants/page-context';

import Shape1 from './shapes/menu-desktop.svg';
import Shape2 from './shapes/menu-desktop-open.svg';
import ShapeTablet from './shapes/header-applat-tablet.svg';
import MenuItemIconConte from './shapes/menu-conte.svg';
import MenuItemIconPedagogique from './shapes/menu-pedagogique.svg';
import MenuItemIconRessources from './shapes/menu-ressources.svg';
import MenuItemIconAudio from './shapes/menu-audio.svg';
import MenuItemIconFAQ from './shapes/menu-faq.svg';
import Link from './Link';
import GLOBAL_MEDIA_QUERIES from '../constants/media-queries';

export default function Header() {
  const data = useStaticQuery(graphql`
    query {
      mobileLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 120, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      desktopLogo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 225, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);
  const sources = [
    data.mobileLogo.childImageSharp.fluid,
    {
      ...data.desktopLogo.childImageSharp.fluid,
      media: GLOBAL_MEDIA_QUERIES.tabletdesktop,
    },
  ];
  return <PageContextConsumer>
    {({ mediaQuery, data: { menuIsOpen }, set }) => <div className={`header ${menuIsOpen ? 'header--open' : ''} ${mediaQuery.desktop ? 'header--desktop' : 'header--notdesktop'}`}>
      <div className="logo">
        <Link to="/" direction="left">
          <Img fluid={sources} />
        </Link>
      </div>
      <button className="menu-opener" onClick={() => set({ menuIsOpen: !menuIsOpen })}>
        { !menuIsOpen ? <MenuIcon /> : <MenuCloseIcon /> }
        <span className="sr-only">Menu</span>
      </button>
      { menuIsOpen ? <ul className="menu-list">
        <li><Link to="/conte" activeClassName="active"><MenuItemIconConte /><span>Le conte</span></Link></li>
        <li><Link to="/pedagogique" activeClassName="active"><MenuItemIconPedagogique /><span>Les contenus pédagogiques</span></Link></li>
        <li><Link to="/ressources" activeClassName="active"><MenuItemIconRessources /><span>Les ressources</span></Link></li>
        <li><Link to="/audio" activeClassName="active"><MenuItemIconAudio /><span>Les contenus audios</span></Link></li>
        <li><Link to="/faq" activeClassName="active"><MenuItemIconFAQ /><span>FAQ</span></Link></li>
      </ul> : null }
      { mediaQuery.desktop ? (!menuIsOpen ? <Shape1 className="shape-bg" /> : <Shape2 className="shape-bg" />) : (mediaQuery.notdesktop ? <ShapeTablet className="shape-bg shape-mobile" preserveAspectRatio="none" /> : null) }
    </div>}
  </PageContextConsumer>
}
