import React, { useState } from 'react';
import loadable from '@loadable/component';

import Link from '../components/Link';

const AddToHomeScreen = loadable(() => import('@ideasio/add-to-homescreen-react'));

const text = {
  alt: `Guide pour installer l'application sur l'écran d'accueil.`,
  action: {
    ok: `OK`,
    cancel: `Annuler`,
    guidanceCancel: `Fermer`,
  },
  custom: {
    title: ``,
    cancelMsg: ``,
    installMsg: `Installer l'application`,
    guidanceCancelMsg: `Fermer`,
  },
};

export default function InstallPrompt() {
  const [nativePromptShown, setNativePromptShown] = useState(false)
  return <>
    {typeof window !== `undefined` ? <AddToHomeScreen
      appId={`pilou-et-fleurette`}
      debug={null}
      activateLogging={false}
      startAutomatically={true}
      startDelay={0}
      mustShowCustomPrompt={true}
      skipFirstVisit={false}
      displayPace={0}
      lifespan={0}
      maxDisplayCount={0}
      onShow={() => setNativePromptShown(true)}
      customPromptContent={{
        ...text.custom,
        src: `/images/icon-install.png`,
      }}
      customPromptPlatformDependencies={{
        native: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/chromium.png`, alt: text.alt } ],
          action: text.action,
        },
        chromium: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/chromium.png`, alt: text.alt } ],
          action: text.action,
        },
        edge: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/chromium.png`, alt: text.alt } ],
          action: text.action,
        },
        iphone: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/iphone.png`, alt: text.alt } ],
          action: text.action,
        },
        ipad: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/ipad.png`, alt: text.alt } ],
          action: text.action,
        },
        firefox: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/firefox.png`, alt: text.alt } ],
          action: text.action,
        },
        samsung: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/firefox.png`, alt: text.alt } ],
          action: text.action,
        },
        opera: {
          targetUrl: '/install',
          images: [ { src: `/images/guidance/opera.png`, alt: text.alt } ],
          action: text.action,
        }
      }}
    /> : null}
    { !nativePromptShown ? <div class="ath-container banner-bottom-center ipad-wrapper animated fadeIn d-block d-grid fake-banner">
      <div class="ath-banner">
        <div class="ath-logo-cell ath-banner-cell">
          <img alt="Install application" class="ath-prompt-logo" src="/images/icon-install.png" />
        </div>
        <div class="ath-install-cell ath-banner-cell">
          <Link to="/install" className="btn-install btn btn-success">Installer l'application</Link>
        </div>
      </div>
    </div> : null }
  </>
}
