import React from 'react';
import Link from './Link';
import { useAuth } from '../constants/use-auth.js';

export default function UserProfile() {
  const auth = useAuth();
  return <>
    <div className="user-profile">
      { auth.user ? <>
        <p>Connecté en tant que : <em>{ auth.user.fullname }</em></p>
        <p><Link to="/change-password" className="btn-reset-password">Mon profil</Link></p>
        <p><button onClick={() => auth.signout()} className="btn-logout">Se déconnecter</button></p>
      </> : <>
        <p>Erreur !<Link to="/" className="btn btn-primary">Retour à l'accueil</Link></p>
      </>}
    </div>
  </>
}
