import React, { useState, useEffect, useContext, createContext } from 'react';
import jwtDecode from 'jwt-decode';

const login_uri = `${process.env.GATSBY_API_URL}/login`;

export const LOCAL_STORAGE_KEY_TOKEN = 'pilou-et-fleurette--front--token';
export const LOCAL_STORAGE_KEY_ROLES = 'pilou-et-fleurette--front--roles';
export const LOCAL_STORAGE_KEY_EXPIRES = 'pilou-et-fleurette--front--expires';
export const LOCAL_STORAGE_KEY_IDENTITY = 'pilou-et-fleurette--front--identity';

const authContext = createContext();

export const getAuthRoles = () => {
  return localStorage.getItem(LOCAL_STORAGE_KEY_ROLES) ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_ROLES)) : [];
};

export const unprotectedPaths = [
  'reset-password',
];

export const cleanAuthLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_KEY_ROLES);
  localStorage.removeItem(LOCAL_STORAGE_KEY_EXPIRES);
  localStorage.removeItem(LOCAL_STORAGE_KEY_IDENTITY);
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);

  /** SIGNIN */
  const signin = (email, password) => {
    const request = new Request(`${login_uri}`, {
      method: 'POST',
      body: JSON.stringify({ username: email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });

    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
        return response.json();
      })
      .then(({ token }) => {
        localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, token);
        const decodedToken = jwtDecode(token);
        const returnedUser = { id: '', fullname: decodedToken.username, avatar: null };
        localStorage.setItem(LOCAL_STORAGE_KEY_ROLES, JSON.stringify(decodedToken.roles));
        localStorage.setItem(LOCAL_STORAGE_KEY_EXPIRES, decodedToken.exp);
        localStorage.setItem(LOCAL_STORAGE_KEY_IDENTITY, JSON.stringify(returnedUser));
        setUser(returnedUser);
        window.document.body.classList.add('with--user--logged-in');
        return returnedUser;
      });
  };

  /** SIGNOUT */
  const signout = () => {
    return Promise.resolve()
      .then(() => {
        cleanAuthLocalStorage();
        setUser(false);
        window.document.body.classList.remove('with--user--logged-in');
      });
  };

  const sendPasswordResetEmail = email => {
    return Promise.resolve();
  };

  const confirmPasswordReset = (code, password) => {
    return Promise.resolve();
  };

  const authorizeRoute = (pageContext) => user || unprotectedPaths.includes(pageContext.pathname || 'home');

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN) && localStorage.getItem(LOCAL_STORAGE_KEY_EXPIRES) && localStorage.getItem(LOCAL_STORAGE_KEY_EXPIRES) > Math.floor(Date.now() / 1000)) {
      setUser(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_IDENTITY)));
      window.document.body.classList.add('with--user--logged-in');
    } else {
      setUser(false);
      window.document.body.classList.remove('with--user--logged-in');
    }
    // Cleanup subscription on unmount
    return () => setUser(false);
  }, []);

  // Return the user object and auth methods
  return {
    user,
    signin,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset,
    authorizeRoute,
  };
}
