import React from 'react'
import GLOBAL_MEDIA_QUERIES from '../constants/media-queries'
import { getLocalObject, setLocalObject } from '../services/utils'

const defaultContextValue = {
  data: {
    menuIsOpen: false,
    isStandalone: false,
    isIOS: false,
    page: 'home',
    title: 'Pilou et Fleurette',
  },
  mediaQuery: {
    mobile: true,
    tablet: false,
    notdesktop: true,
    tabletdesktop: false,
    desktop: false,
  },
  set: () => {},
}

const iosPlatforms = [ 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod' ];

const { Provider, Consumer } = React.createContext(defaultContextValue)

const getMediaMatches = () => ({
  mobile: window ? !!window.matchMedia(GLOBAL_MEDIA_QUERIES.mobile).matches : true,
  tablet: window ? !!window.matchMedia(GLOBAL_MEDIA_QUERIES.tablet).matches : false,
  notdesktop: window ? !!window.matchMedia(GLOBAL_MEDIA_QUERIES.notdesktop).matches : true,
  tabletdesktop: window ? !!window.matchMedia(GLOBAL_MEDIA_QUERIES.tabletdesktop).matches : false,
  desktop: window ? !!window.matchMedia(GLOBAL_MEDIA_QUERIES.desktop).matches : false,
})

class PageContextProviderComponent extends React.Component {
  _isMounted = false

  constructor() {
    super()

    this.setData = this.setData.bind(this)
    this.onResize = this.onResize.bind(this)
    this.state = {
      ...defaultContextValue,
      set: this.setData,
    }
  }

  componentDidMount() {
    this._isMounted = true
    const localData = getLocalObject('pageContextData')
    this.setState(state => ({
      data: localData ? {
        ...localData,
        ...(this.props.values || {}),
        menuIsOpen: false,
        isStandalone: window ? ((window.matchMedia('(display-mode: standalone) or (display-mode: minimal-ui)').matches) || (window.navigator.standalone)) : false,
        isIOS: window && document && window.navigator && window.navigator.platform ? (iosPlatforms.includes(window.navigator.platform) || (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document)) : false,
      } : {
        ...state.data,
        isStandalone: window ? ((window.matchMedia('(display-mode: standalone) or (display-mode: minimal-ui)').matches) || (window.navigator.standalone)) : false,
        isIOS: window && document && window.navigator && window.navigator.platform ? (iosPlatforms.includes(window.navigator.platform) || (window.navigator.userAgent.includes('Mac') && 'ontouchend' in document)) : false,
      },
      mediaQuery: getMediaMatches(),
    }))
    window.addEventListener('resize', this.onResize);
  }
  
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.onResize);
  }
  
  setData(newData) {
    const mergedData = {
      ...this.state.data,
      ...newData,
    }
    setLocalObject('pageContextData', mergedData)
    if ( this._isMounted ) {
      this.setState({ data: mergedData })
    }
  }

  onResize() {
    this.setState(() => ({
      mediaQuery: getMediaMatches(),
    }))
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>
  }
}

export { Consumer as default, PageContextProviderComponent }
