import React from 'react';

// import { GrPrevious as PrevIcon, GrNext as NextIcon } from 'react-icons/gr';

import PageContextConsumer from '../constants/page-context';

import Link from './Link';
import Profile from './UserProfile';
import Applat from './shapes/header-applat.svg';

const logos = [
  { img: 'svea', name: 'SVEA' },
  { img: 'canton-fribourg', name: 'Canton Fribourg' },
  { img: 'canton-valais', name: 'Canton Valais' },
  { img: 'canton-vaud', name: 'Canton Vaud' },
  { img: 'csmb', name: 'CSMB' },
  { img: 'sea73', name: 'SEA 73' },
  { img: 'sea74', name: 'SEA 74' },
  { img: 'confederation-suisse', name: 'Confédération Suisse' },
  { img: 'interreg', name: 'interreg' },
  { img: 'seavr', name: 'SEAVR' },
  { img: 'sfea', name: 'SFEA' },
];

export default function Footer() {
  return <PageContextConsumer>
    {({ mediaQuery }) => <div className="footer">
      <div className="partners">
        <div className="container">
          <h2>Nos partenaires</h2>
        </div>
        <div class="container wrapper-partners">
          <div class="wrapper">
            <div class="partners-block">
              <h3>Coordinateur :</h3>
              <div className="logo"><img src={`/images/partners/logo-${logos[6].img}.png`} alt={`Logo ${logos[6].name}`} /></div>
            </div>
            <div class="partners-block">
              <h3>En collaboration avec :</h3>
              <div className="logo"><img src={`/images/partners/logo-${logos[5].img}.png`} alt={`Logo ${logos[5].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[9].img}.png`} alt={`Logo ${logos[9].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[10].img}.png`} alt={`Logo ${logos[10].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[0].img}.png`} alt={`Logo ${logos[0].name}`} /></div>
            </div>
            <div class="partners-block">
              <h3>Conception et production financées par :</h3>
              <div className="logo"><img src={`/images/partners/logo-${logos[8].img}.png`} alt={`Logo ${logos[8].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[4].img}.png`} alt={`Logo ${logos[4].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[7].img}.png`} alt={`Logo ${logos[7].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[1].img}.png`} alt={`Logo ${logos[1].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[3].img}.png`} alt={`Logo ${logos[3].name}`} /></div>
              <div className="logo"><img src={`/images/partners/logo-${logos[2].img}.png`} alt={`Logo ${logos[2].name}`} /></div>
            </div>
          </div>
        </div>
        <div className="container">
          <p className="small">Projet soutenu par le programme européen de coopération transfrontalière Interreg France-Suisse 2014-2020 et bénéficiant d’une subvention européenne (Fonds européen de développement Régional) FEDER 65% du coût français et Fédérale 47 % du coût suisse.</p>
        </div>
      </div>
      <div className="footer-menu">
        <div className="container">
          <ul>
            <li><p>&copy; {new Date().getFullYear()}</p></li>
            <li><p>tous droits réservés</p></li>
            <li><Link to="/mentions-legales" activeClassName="active">Mentions légales</Link></li>
            <li><Link to="/cgu" activeClassName="active">CGU</Link></li>
            <li><Link to="/politique-confidentialite" activeClassName="active">Politique de confidentialité</Link></li>
            <li><Link to="/contact" activeClassName="active">Nous contacter</Link></li>
          </ul>
          <Profile />
        </div>
      </div>
      { mediaQuery.desktop ? <Applat className="header-applat" /> : null }
    </div>}
  </PageContextConsumer>
}
